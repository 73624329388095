<template>
  <div>
    <Pane :title="name" />

    <div class="container" style="background: #fff; padding: 12px">
      <div class="preview">
        <a-button size="small" @click="onPrevPage">上一页</a-button>
        <div class="page-num">
          <span>{{ pageNum }}</span>
          <span v-if="pdfDocument">/ {{ pdfDocument.numPages }}</span>
        </div>
        <a-button type="primary" size="small" @click="onNextPage">下一页</a-button>
      </div>

      <div class="canvas-wrapper">
        <canvas id="canvas"></canvas>
        <div id="water"></div>
      </div>

      <div class="preview">
        <a-button size="small" @click="onPrevPage">上一页</a-button>
        <div class="page-num">
          <span>{{ pageNum }}</span>
          <span v-if="pdfDocument">/ {{ pdfDocument.numPages }}</span>
        </div>
        <a-button type="primary" size="small" @click="onNextPage">下一页</a-button>
      </div>

      <div class="center" style="padding: 24px 0;">
        <a-button @click="$close($route.path)">关闭</a-button>
      </div>
    </div>
  </div>
</template>
  
  
<script>
import watermark from "@/mixins/watermark";
export default {
  mixins: [watermark],

  data() {
    return {
      name: "",
      path: "",

      pageNum: 1,
      scale: 1.5,
      pdfDocument: null,

      loading: false
    };
  },

  mounted() {
    this.setWatermark(document.getElementById("water"), "#ddd");

    const name = window.localStorage.getItem("think-pdf-name");
    const path = window.localStorage.getItem("think-pdf-path");
    this.name = name;
    if (path) {
      this.path = path;
      this.renderPDF(path);
      // this.renderPDF('/【精品】某科技股份有限公司 城市智慧停车整体解决方案.pdf')
    }
  },

  methods: {
    renderPDF(url) {
      const that = this;

      const loadingTask = window.pdfjsLib.getDocument(url);

      this.loading = true;

      loadingTask.promise
        .then(function(pdfDocument) {
          that.pdfDocument = pdfDocument;
          that.renderPage();
          that.loading = false;
        })
        .catch(function(reason) {
          console.error("Error: " + reason);
          that.loading = false;
        });
    },

    renderPage() {
      const { pdfDocument, pageNum, scale } = this;
      if (!pdfDocument) {
        console.log("There is no pdfDocument!");
        return;
      }
      pdfDocument.getPage(pageNum).then(function(pdfPage) {
        const viewport = pdfPage.getViewport({ scale });
        const canvas = document.getElementById("canvas");
        canvas.width = viewport.width;
        canvas.height = viewport.height;
        const ctx = canvas.getContext("2d");
        pdfPage.render({
          canvasContext: ctx,
          viewport
        });
      });
    },

    onPrevPage() {
      if (this.pageNum <= 1) {
        return;
      }
      this.pageNum--;
      this.renderPage();
    },
    onNextPage() {
      if (this.pageNum >= this.pdfDocument.numPages) {
        return;
      }
      this.pageNum++;
      this.renderPage();
    }
  }
};
</script>
  
<style lang="less" scoped>
.preview {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 12px 0;

  .left-arrow {
    margin-left: auto;
  }

  .page-num {
    margin: 0 8px;
  }

  .left-arrow,
  .right-arrow {
    cursor: pointer;
    border: 1px solid #d0d0d0;
    background-color: #fff;
    font-size: 12px;
    padding: 4px 8px;
    border-radius: 2px;
    text-align: center;
  }
}

.canvas-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow-x: auto;
  overflow-y: auto;

  #water {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
</style>
  